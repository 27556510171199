import React, {useEffect, useState} from "react";
import {
    Box,
    CircularProgress,
    InputAdornment,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import {type AutoReportType, type ReportType} from "react_ct/types";
import {InspectionTabs} from "./components/Details/InspectionTabs";
import {AccessScoreIndicator, dwStatusToColor, scoreToColor} from "./components/components";
import DWMap from "components/Map/DWMap";
import {useProject} from "contexts/ProjectContext";
import LoadingError from "components/LoadingError";
import LoadingScreen from "components/LoadingScreen";
import {apiRequest} from "react_ct/requests";
import {FolderOpen, Search} from "@mui/icons-material";
import {ZoomCanvas} from "./components/Details/ZoomCanvas";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType, scanKeys} from "queries/queries";

/**
 *
 * @returns The data page
 */
export const DataDetailsPage: React.FC = () => {
    const theme = useTheme();
    const projectContext = useProject();
    const {currentProject: project, projectList: projectsData, projectsError} = projectContext;

    const [projectDataError, setProjectDataError] = useState<string>();
    const [selectedReportIndex, setSelectedReportIndex] = useState<number>();
    const [selectedScanId, setSelectedScanId] = useState<string>("");
    const [currentDataCategory, setCurrentDataCategory] = useState<DataCategory>("Inspection Summary");
    const [selectedMapView, setSelectedMapView] = useState<"accessibility" | "curb" | "dw">("accessibility");
    const [selectedFeature, setSelectedFeature] = useState<GeoJSON.Feature>();
    const [selectedCanvasItemId, setSelectedCanvasItemId] = useState<string | null>(null);
    const [noScanFound, setNoScanFound] = useState(false);

    const {data: geojsonMetaData, error: geojsonError} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        enabled: !!project,
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
    });

    const formattedData: GeoJSON.FeatureCollection = React.useMemo<GeoJSON.FeatureCollection>(() => {
        return {
            type: "FeatureCollection",
            features: geojsonMetaData?.geojsonData
                ? [
                      ...geojsonMetaData.geojsonData.features.map(feature => {
                          return {
                              ...feature,
                              properties: {
                                  ...feature.properties,
                                  color: scoreToColor(feature.properties as ReportType),
                                  filter_type: "accessibility",
                              },
                          };
                      }),
                      ...geojsonMetaData.geojsonData.features
                          .filter(feature => feature.properties?.type)
                          .map(feature => {
                              return {
                                  ...feature,
                                  properties: {
                                      ...feature.properties,
                                      color: scoreToColor(feature.properties as ReportType),
                                      filter_type: "curb",
                                  },
                              };
                          }),
                      ...geojsonMetaData.geojsonData.features
                          .filter(feature => feature.properties?.type)
                          .map(feature => {
                              return {
                                  ...feature,
                                  properties: {
                                      ...feature.properties,
                                      color: dwStatusToColor(feature.properties as ReportType),
                                      filter_type: "dw",
                                  },
                              };
                          }),
                  ]
                : [],
        };
    }, [geojsonMetaData, selectedMapView]);

    const selectDataPoint = (feature: GeoJSON.Feature): void => {
        const findIndex = geojsonMetaData?.geojsonData?.features.findIndex(
            item => item.properties?.id === feature.properties?.id,
        );
        setSelectedReportIndex(findIndex);
    };

    const noDataAvailable = geojsonMetaData?.geojsonData && formattedData.features.length;

    const {data: s3AutoreportUrl} = useQuery({
        queryKey: scanKeys.scanAutoreportUrl(
            geojsonMetaData?.geojsonData?.features[selectedReportIndex ?? 0].properties?.id,
        ),
        enabled: !!geojsonMetaData,
        queryFn: async ({queryKey}) => {
            const folderName = geojsonMetaData?.geojsonData?.features[selectedReportIndex ?? 0].properties?.folder_name;
            const scanId = queryKey[1];
            if (folderName) {
                const res = await apiRequest({
                    path: `scan/autoreport`,
                    params: {
                        folderName,
                        scanId,
                    },
                });

                if (res.data.autoreportUrl) {
                    return res.data.autoreportUrl;
                } else {
                    throw new Error("No autoreport url found");
                }
            }
        },
    });

    useEffect(() => {
        if (geojsonMetaData?.geojsonData) {
            selectDataPoint(geojsonMetaData.geojsonData.features[0]);
        }
    }, [geojsonMetaData, selectedMapView]);

    useEffect(() => {
        if (projectsData?.length !== undefined && projectsData.length === 0) {
            setProjectDataError("No projects available");
        } else {
            setProjectDataError(undefined);
        }
    }, [projectsData]);

    useEffect(() => {
        setSelectedCanvasItemId(null);
    }, [selectedFeature]);

    const handleChange = (event: React.SyntheticEvent, newValue: DataCategory): void => {
        setCurrentDataCategory(newValue);
    };

    React.useEffect(() => {
        if (selectedReportIndex !== undefined) {
            setNoScanFound(false);
            const id = geojsonMetaData?.geojsonData.features[selectedReportIndex].properties?.id;
            const matchingFeature = formattedData.features.find(feature => feature.properties?.id === id);
            if (matchingFeature) setSelectedFeature(matchingFeature);
            if (id !== selectedScanId) setSelectedScanId(String(id));
        }
    }, [selectedReportIndex]);

    return (
        <Box p={3} height="100vh" boxSizing="border-box">
            {(geojsonError ?? projectsError ?? projectDataError) ? (
                <LoadingError errorMessage={String(geojsonError?.message ?? projectsError)} />
            ) : geojsonMetaData?.geojsonData && selectedReportIndex !== undefined ? (
                <Stack sx={{height: "100%"}}>
                    <Stack direction="row" gap={3} sx={{flexGrow: 1}}>
                        <Stack gap={1} flexBasis="55%">
                            <Stack direction="row" gap={5} justifyContent="space-between" alignItems="baseline">
                                <Stack direction="row" gap={0.5} alignItems="baseline">
                                    <Typography
                                        component="h2"
                                        variant="body1"
                                        color={theme.palette.midnightBlue.light}
                                        fontWeight={700}
                                        fontSize="1.5rem">
                                        ID:
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        value={selectedScanId}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Search color="disabled" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setSelectedScanId(event.target.value);
                                            if (event.target.value.length === 6) {
                                                const matchingIndex = geojsonMetaData?.geojsonData.features.findIndex(
                                                    feature => feature.properties?.id === parseInt(event.target.value),
                                                );
                                                if (matchingIndex < 0) setNoScanFound(true);
                                                else {
                                                    setNoScanFound(false);
                                                    setSelectedReportIndex(matchingIndex);
                                                }
                                            } else {
                                                if (noScanFound) setNoScanFound(false);
                                            }
                                        }}
                                        error={noScanFound}
                                        helperText="Could not find scan with given ID"
                                        sx={{
                                            p: 0,
                                            [`& .MuiInputBase-input`]: {
                                                fontFamily: "'Lato', sans-serif",
                                                fontWeight: 700,
                                                fontSize: "1.5rem",
                                                color: theme.palette.midnightBlue.light,
                                                background: "transparent",
                                                outline: "none",
                                                width: "6em",
                                                pl: 0.5,
                                                py: 3,
                                                boxSizing: "border-box",
                                            },
                                        }}
                                    />
                                </Stack>
                                <AccessScoreIndicator
                                    report={
                                        geojsonMetaData.geojsonData?.features[selectedReportIndex]
                                            .properties as ReportType
                                    }
                                />
                            </Stack>
                            {geojsonMetaData?.geojsonData ? (
                                <ZoomCanvas
                                    autoreportUrl={s3AutoreportUrl}
                                    rasterUrl={
                                        geojsonMetaData.geojsonData?.features[selectedReportIndex].properties
                                            ?.raster_media
                                    }
                                    scanData={
                                        geojsonMetaData.geojsonData?.features[selectedReportIndex]
                                            .properties as AutoReportType
                                    }
                                    {...{selectedCanvasItemId, setSelectedCanvasItemId}}
                                />
                            ) : (
                                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                                    <Stack alignItems="center" gap={2}>
                                        <CircularProgress />
                                        <Typography fontSize="0.9rem" variant="subtitle2">
                                            Retrieving scan image...
                                        </Typography>
                                    </Stack>
                                </Box>
                            )}
                        </Stack>
                        <Stack direction="column" flexBasis="45%" flexShrink={1} gap={3}>
                            <Box
                                sx={theme => ({
                                    height: "40vh",
                                    position: "relative",
                                    boxShadow: theme.shadows[1],
                                    borderRadius: theme.shape.borderRadius,
                                    border: "1px solid #ffffffaa",
                                    borderBottom: "none",
                                })}>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 5,
                                        left: 5,
                                        zIndex: 3,
                                        maxWidth: "95%",
                                    }}>
                                    <ToggleButtonGroup
                                        exclusive
                                        value={selectedMapView}
                                        aria-label="Select map view"
                                        onChange={(
                                            event: React.MouseEvent<HTMLElement>,
                                            newValue: "accessibility" | "curb" | "dw",
                                        ) => {
                                            if (newValue !== null) setSelectedMapView(newValue);
                                        }}
                                        sx={theme => ({
                                            gap: 1,
                                            "& .MuiToggleButtonGroup-grouped": {
                                                padding: "2px 10px",
                                                borderRadius: "50px",
                                                backgroundColor: "#ffffffcc",
                                                backdropFilter: "blur(4px)",
                                                boxShadow: theme.shadows[3],

                                                "&.Mui-selected": {
                                                    backgroundColor: theme.palette.deepWalkBlue.main,
                                                    color: theme.palette.text.secondary,
                                                    border: "none",
                                                },
                                            },
                                            "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                                                borderRadius: "50px",
                                            },
                                            "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                                                borderRadius: "50px",
                                            },
                                        })}>
                                        <ToggleButton value="accessibility" aria-label="Accessibility score">
                                            Accessibility Score
                                        </ToggleButton>
                                        <ToggleButton value="curb" aria-label="Curb ramp">
                                            Curb Ramp
                                        </ToggleButton>
                                        <ToggleButton value="dw" aria-label="Detectable warning">
                                            Detectable Warning
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <DWMap
                                    satelliteControl
                                    satelliteControlSize="lg"
                                    onMarkerClick={selectDataPoint}
                                    data={formattedData}
                                    filterLayers={[
                                        {
                                            id: "filter_type",
                                            layers: [String(selectedMapView)],
                                        },
                                    ]}
                                    dataId="id"
                                    selectedFeature={selectedFeature}
                                    borderRadius
                                />
                            </Box>
                            <InspectionTabs
                                currentDataCategory={currentDataCategory}
                                handleChange={handleChange}
                                geojsonData={geojsonMetaData?.geojsonData}
                                lastModified={
                                    geojsonMetaData?.lastModified ? new Date(geojsonMetaData.lastModified) : null
                                }
                                selectedReportIndex={selectedReportIndex}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            ) : noDataAvailable ? (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Stack spacing={2} alignItems="center">
                        <FolderOpen
                            sx={theme => ({
                                color: theme.palette.midnightBlue.light,
                                fontSize: "5rem",
                            })}
                        />
                        <Typography variant="h3" color={theme => theme.palette.midnightBlue.light}>
                            No data available
                        </Typography>
                    </Stack>
                </Box>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
};
