/* eslint-disable react/jsx-no-undef */
import React from "react";
import {
    Route,
    useLocation,
    Navigate,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {theme} from "react_ct/theme";
import {AuthProvider, useAuth} from "contexts/AuthContext";
import {FrontContent} from "routes/front/Content";
import {PortalContent} from "routes/portal/Content";
import {ProjectProvider} from "contexts/ProjectContext";
import {getCookie} from "react_ct/requests";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Home} from "pages/front/home/Home";
import Oauth from "pages/portal/Oauth";
import NotFound from "pages/not-found/NotFound";
import HomePage from "pages/portal/Home";
import {ScanMap} from "pages/portal/collect/Map";
import {ProjectTags} from "pages/portal/collect/ProjectTags";
import Plan from "pages/portal/collect/Plan";
import AdjustPage from "pages/portal/collect/Adjust";
import {DataOverviewPage} from "pages/portal/data/Overview";
import {DataDetailsPage} from "pages/portal/data/Details";
import ArcGISPage from "pages/portal/data/ArcGIS";
import CreateProgramHome from "pages/portal/implement/create/CreateProgramHome";
import ComingSoon from "pages/coming-soon/ComingSoon";
import ProgramSelectFeatures from "pages/portal/implement/create/select/ProgramSelectFeatures";
import ReviewNewProgram from "pages/portal/implement/create/review/ReviewNewProgram";
import ProjectPrograms from "pages/portal/implement/programs/ProjectPrograms";
import {UserSettings} from "pages/portal/settings/Settings";
import ProgramDashboard from "pages/portal/implement/program/ProgramDashboard";
import {shouldUseBetaEnv} from "react_ct/utils";

const queryClient = new QueryClient();

export const App: React.FC = () => {
    const beta = shouldUseBetaEnv();

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/portal/*"
                    element={
                        <RequireAuth>
                            <ProjectProvider>
                                <PortalContent />
                            </ProjectProvider>
                        </RequireAuth>
                    }>
                    <Route path="" element={<HomePage />}></Route>

                    <Route path="collect/map" element={<ScanMap />}></Route>

                    <Route path="collect/tags" element={<ProjectTags />}></Route>
                    <Route path="collect/plan" element={<Plan />}></Route>
                    <Route path="collect/adjust" element={<AdjustPage />}></Route>

                    <Route path="data/overview" element={<DataOverviewPage />}></Route>
                    <Route path="data/details" element={<DataDetailsPage />}></Route>
                    <Route path="data/arcgis" element={<ArcGISPage />}></Route>

                    <Route path="implement/create" element={<CreateProgramHome />}></Route>
                    <Route path="implement/create/:type" element={<ProgramSelectFeatures />}></Route>
                    <Route path="implement/create/:type/review" element={<ReviewNewProgram />}></Route>
                    <Route path="implement/programs" element={<ProjectPrograms />}></Route>
                    <Route
                        path="implement/program/:programId"
                        element={beta ? <ProgramDashboard /> : <ComingSoon />}></Route>

                    <Route path="settings" element={<UserSettings />}></Route>

                    <Route path="*" element={<NotFound />}></Route>
                </Route>
                <Route path="/*" element={<FrontContent />}>
                    <Route path="" element={<Home />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                    <Route path="oauth" element={<Oauth />}></Route>
                </Route>
            </>,
        ),
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider {...{router}} />
                    </QueryClientProvider>
                </AuthProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

interface RequireAuthProps {
    children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = (props: RequireAuthProps) => {
    const {children} = props;

    const auth = useAuth();
    const location = useLocation();

    // the useLocalStorage hook causes an infinite loop so we have to use the localStorage API directly
    const userData = localStorage.getItem("user");

    const jwt = getCookie("accessToken");

    // this check happens before auth.user is initialized in the auth context, so we should also check the cookies and local storage for existing user data
    if (!auth.user && (!userData || userData.length === 0 || !jwt || jwt.length === 0)) {
        // Redirect them to the home page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{from: location}} replace />;
    }

    return children;
};
