import React, {Fragment, useEffect, useState} from "react";
import {debounce} from "lodash";
import {
    Divider,
    Drawer,
    styled,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListSubheader,
    Box,
    Select,
    MenuItem,
    type SelectChangeEvent,
    Typography,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import {
    Analytics,
    ExpandLess,
    ExpandMore,
    Logout,
    Settings,
    LabelImportant,
    Summarize,
    PlaylistAdd,
    BarChart,
    SettingsSuggest,
    EditRoad,
    Draw,
    FormatListBulleted,
    NextPlan,
    PublishedWithChanges,
    CompassCalibration,
    Home,
} from "@mui/icons-material";
import {useAuth} from "contexts/AuthContext";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {colors, roboto} from "react_ct/theme";
import logo from "assets/White_Horizontal_TP.png";
import smallLogo from "assets/White_TP.png";
import {useProject} from "contexts/ProjectContext";
import {useLayout} from "contexts/LayoutContext";
import {shouldUseBetaEnv} from "react_ct/utils";

const OPEN_WIDTH = 200;
const CLOSED_WIDTH = 48;

const DrawerHeader = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    color: colors.white,
    ...theme.mixins.toolbar,
}));

/** Should the category be open? */
interface CategoryState {
    Collect: boolean;
    Data: boolean;
    Implement: boolean;
    Transition: boolean;
}

type PageCategory = keyof CategoryState | "Home";

export interface Route {
    /** The route path */
    path: string;
    /** The route's label on the sidebar */
    sidebarName: string;
    /** The icon associated with the route */
    icon?: JSX.Element;
    adminOnly?: boolean;
    inBeta?: boolean;
}

interface RouteInfo {
    /** The page category */
    category: PageCategory;
    /** The main route for this category */
    mainRoute: string;
    /** The list of routes under the category */
    inBeta?: boolean;
    routes?: Route[];
}

/**
 * The list of routes for the navigation drawer
 */
const routeList: RouteInfo[] = [
    {
        category: "Home",
        mainRoute: "/portal",
    },
    {
        category: "Collect",
        mainRoute: "/portal/collect",
        routes: [
            {
                path: "/portal/collect/map",
                sidebarName: "Map",
                icon: <MapIcon />,
            },
            {
                path: "/portal/collect/tags",
                sidebarName: "Tags",
                icon: <LabelImportant />,
            },
            {
                path: "/portal/collect/adjust",
                sidebarName: "Adjust",
                icon: <PublishedWithChanges />,
            },
            {
                path: "/portal/collect/plan",
                sidebarName: "Plan",
                icon: <NextPlan />,
            },
        ],
    },
    {
        category: "Data",
        mainRoute: "/portal/data",
        routes: [
            {
                path: "/portal/data/overview",
                sidebarName: "Overview",
                icon: <Summarize />,
            },
            {
                path: "/portal/data/details",
                sidebarName: "Details",
                icon: <Analytics />,
            },
            {
                path: "/portal/data/arcgis",
                sidebarName: "ArcGIS",
                icon: <CompassCalibration />,
            },
        ],
    },
    {
        category: "Implement",
        mainRoute: "/portal/implement",
        routes: [
            {
                path: "/portal/implement/create",
                sidebarName: "Create",
                icon: <Draw />,
            },
            {
                path: "/portal/implement/programs",
                sidebarName: "Programs",
                icon: <FormatListBulleted />,
            },
        ],
    },
    {
        category: "Transition",
        mainRoute: "/portal/transition",
        routes: [],
    },
];

/**
 * The navigation drawer component
 * @returns The navigation drawer component
 */
export const NavDrawer: React.FC = () => {
    const beta = shouldUseBetaEnv();

    const {projectList, currentProject, changeProject} = useProject();
    const {signout, checkIsAdmin} = useAuth();
    const {openNav, setOpenNav} = useLayout();
    const drawerRef = React.useRef<HTMLDivElement>(null);
    const drawerPaperRef = React.useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [projectDropdownValue, setProjectDropdownValue] = useState(currentProject?.id ?? "");

    useEffect(() => {
        if (currentProject) setProjectDropdownValue(currentProject.id);
    }, [currentProject]);

    const getCurrentCategory = (): PageCategory | null => {
        return (
            routeList.filter(routeInfo =>
                routeInfo.routes
                    ? routeInfo.routes.filter(route => route.path === path).length > 0
                    : routeInfo.mainRoute === path,
            )[0]?.category ?? null
        );
    };

    const location = useLocation();

    // state variables
    const [path, setPath] = useState<string>(location.pathname);
    const [currentCategory, setCurrentCategory] = useState<PageCategory | null>(getCurrentCategory());

    useEffect(() => {
        setPath(location.pathname);
        setCurrentCategory(getCurrentCategory());
    }, [location]);

    const [openCategory, setOpenCategory] = useState<CategoryState>({
        Collect: currentCategory === "Collect",
        Data: currentCategory === "Data",
        Implement: currentCategory === "Implement",
        Transition: currentCategory === "Transition",
    });

    const toggleNavCategory = (category: keyof CategoryState): void => {
        setOpenCategory({...openCategory, [category]: !openCategory[category]});
    };

    const openDrawer = debounce(() => {
        if (drawerRef.current && drawerPaperRef.current) {
            const drawerWidth = drawerPaperRef.current.clientWidth;
            if (drawerWidth === CLOSED_WIDTH) setOpenNav(true);
        }
    }, 300);

    const closeDrawer = React.useCallback(() => {
        if (drawerRef.current && drawerPaperRef.current) {
            setOpenNav(false);
            openDrawer.cancel();
        }
    }, [openDrawer]);

    return (
        <Box position="absolute">
            <Drawer
                ref={drawerRef}
                variant="permanent"
                PaperProps={{
                    ref: drawerPaperRef,
                }}
                onMouseEnter={openDrawer}
                onMouseLeave={closeDrawer}
                sx={theme => ({
                    overflow: "hidden",
                    width: openNav ? OPEN_WIDTH : CLOSED_WIDTH,
                    transition: openNav
                        ? theme.transitions.create(["width"], {
                              easing: theme.transitions.easing.easeOut,
                              duration: theme.transitions.duration.enteringScreen,
                          })
                        : theme.transitions.create(["width"], {
                              easing: theme.transitions.easing.easeIn,
                              duration: theme.transitions.duration.leavingScreen,
                          }),
                    "& .MuiDrawer-paper": {
                        overflow: "hidden",
                        width: openNav ? OPEN_WIDTH : CLOSED_WIDTH,
                        boxSizing: "border-box",
                        border: "none",
                        boxShadow: theme.shadows[6],
                        transition: openNav
                            ? theme.transitions.create(["width"], {
                                  easing: theme.transitions.easing.easeOut,
                                  duration: theme.transitions.duration.enteringScreen,
                              })
                            : theme.transitions.create(["width"], {
                                  easing: theme.transitions.easing.easeIn,
                                  duration: theme.transitions.duration.leavingScreen,
                              }),
                    },
                })}>
                <DrawerHeader sx={{gap: 2, py: 1, px: openNav ? 2 : 1}}>
                    <Box component={Link} to="/portal" aria-hidden={true} textAlign="center">
                        <img
                            src={openNav ? logo : smallLogo}
                            alt="DeepWalk Logo"
                            height={35}
                            style={{pointerEvents: "none"}}
                        />
                    </Box>
                </DrawerHeader>
                <Divider sx={{borderColor: "#00000020"}} />
                <Typography
                    variant="h6"
                    sx={{
                        color: colors.offWhite,
                        mt: 1,
                        px: 2,
                        fontFamily: roboto,
                        fontSize: "0.9rem",
                        opacity: 0.5,
                        visibility: openNav ? "visible" : "hidden",
                    }}>
                    Project
                </Typography>
                <Select
                    labelId="select-project-label"
                    id="select-project"
                    value={projectDropdownValue.toString()}
                    variant="standard"
                    label={openNav ? "Project" : ""}
                    placeholder="Select a project"
                    color="secondary"
                    onChange={(event: SelectChangeEvent) => {
                        const selectedProject = projectList?.find(p => p.id === parseInt(event.target.value));
                        if (selectedProject) {
                            if (changeProject) changeProject?.(selectedProject);
                        }
                        setProjectDropdownValue(event.target.value);
                    }}
                    sx={theme => ({
                        width: openNav ? OPEN_WIDTH : CLOSED_WIDTH,
                        color: theme.palette.text.secondary,
                        px: openNav ? 2 : 0,
                        pb: 1,
                        transition: "background-color 0.2s ease-in-out",
                        userSelect: "none",
                        visibility: openNav ? "visible" : "hidden",

                        "&:hover": {
                            backgroundColor: theme.palette.midnightBlue.dark + "33",
                        },

                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "none",
                        },

                        "&::before": {
                            borderBottom: "none",
                        },

                        "& .MuiSelect-icon": {
                            color: theme.palette.text.secondary,
                        },

                        "& .MuiSelect-select": {
                            visibility: openNav ? "visible" : "hidden",
                        },

                        "&& .MuiTypography-root": {
                            display: openNav ? "block" : "none",
                        },
                    })}>
                    {projectList?.map(project => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
                <Divider sx={{borderColor: "#00000020"}} />
                <div
                    style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <List component="nav">
                        {routeList
                            .filter(routeObj => (beta ? true : !routeObj.inBeta))
                            .map(routeObj => (
                                // map each category to a list item
                                <Fragment key={routeObj.category}>
                                    <ListItemButton
                                        onClick={() =>
                                            routeObj.category === "Home"
                                                ? navigate("/portal")
                                                : toggleNavCategory(routeObj.category)
                                        }
                                        sx={theme => ({
                                            "&.Mui-selected": {
                                                backgroundColor: `${theme.palette.orange.dark}66`,
                                            },
                                        })}
                                        selected={path === routeObj.mainRoute}
                                        color="info"
                                        aria-expanded={
                                            openCategory[routeObj.category as keyof CategoryState] ? "true" : "false"
                                        }>
                                        <ListItemText
                                            primary={routeObj.category}
                                            sx={theme => ({
                                                "& .MuiListItemText-primary": {
                                                    color: theme.palette.text.secondary,
                                                    fontFamily: roboto,
                                                    fontSize: "1.2rem",
                                                    visibility: openNav ? "visible" : "hidden",
                                                },
                                            })}
                                        />
                                        {openCategory[routeObj.category as keyof CategoryState] &&
                                        routeObj.category !== "Home" ? (
                                            <ExpandLess />
                                        ) : openNav && routeObj.category !== "Home" ? (
                                            <ExpandMore />
                                        ) : routeObj.category === "Home" && !openNav ? (
                                            <Home color="secondary" sx={{opacity: 0.5}} fontSize="small" />
                                        ) : routeObj.category === "Collect" ? (
                                            <PlaylistAdd
                                                color="secondary"
                                                sx={{
                                                    opacity: 0.5,
                                                }}
                                                fontSize="small"
                                            />
                                        ) : routeObj.category === "Data" ? (
                                            <BarChart
                                                color="secondary"
                                                sx={{
                                                    opacity: 0.5,
                                                }}
                                                fontSize="small"
                                            />
                                        ) : routeObj.category === "Implement" ? (
                                            <EditRoad
                                                color="secondary"
                                                sx={{
                                                    opacity: 0.5,
                                                }}
                                                fontSize="small"
                                            />
                                        ) : routeObj.category === "Transition" ? (
                                            <SettingsSuggest
                                                color="secondary"
                                                sx={{
                                                    opacity: 0.5,
                                                }}
                                                fontSize="small"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </ListItemButton>
                                    {routeObj.routes && (
                                        <Collapse
                                            in={openCategory[routeObj.category as keyof CategoryState]}
                                            timeout="auto"
                                            unmountOnExit>
                                            <List component="div" disablePadding>
                                                {routeObj.routes
                                                    ?.filter(
                                                        route =>
                                                            !route.adminOnly ||
                                                            (route.adminOnly && checkIsAdmin && checkIsAdmin()),
                                                    )
                                                    .filter(route => !route.inBeta || (route.inBeta && beta))
                                                    .map(route => (
                                                        // map each route to a list item
                                                        // nested list inside the category
                                                        <ListItemButton
                                                            sx={theme => ({
                                                                pl: openNav ? 4 : 2,
                                                                "&.Mui-selected": {
                                                                    backgroundColor: `${theme.palette.orange.dark}66`,
                                                                },
                                                            })}
                                                            key={route.path}
                                                            selected={Boolean(path.match(route.path))}
                                                            component={Link}
                                                            to={route.path}>
                                                            {route.icon && (
                                                                <ListItemIcon
                                                                    sx={theme => ({
                                                                        color: path.match(route.path)
                                                                            ? theme.palette.orange.dark
                                                                            : openNav
                                                                              ? theme.palette.midnightBlue.light
                                                                              : "white",
                                                                    })}>
                                                                    {route.icon}
                                                                </ListItemIcon>
                                                            )}
                                                            <ListItemText
                                                                inset={!route.icon}
                                                                primary={route.sidebarName}
                                                                sx={theme => ({
                                                                    "& .MuiListItemText-primary": {
                                                                        color: theme.palette.text.secondary,
                                                                        visibility: openNav ? "visible" : "hidden",
                                                                    },
                                                                })}
                                                            />
                                                        </ListItemButton>
                                                    ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </Fragment>
                            ))}
                    </List>
                    <List
                        aria-labelledby="user-settings-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="user-settings-subheader"
                                sx={{
                                    background: "none",
                                    color: colors.offWhite,
                                    visibility: openNav ? "visible" : "hidden",
                                }}>
                                Options
                            </ListSubheader>
                        }>
                        <ListItemButton component={Link} to="/portal/settings">
                            <ListItemIcon sx={theme => ({color: theme.palette.midnightBlue.light})}>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText
                                primary="Settings"
                                sx={theme => ({
                                    "& .MuiListItemText-primary": {
                                        color: theme.palette.text.secondary,
                                        visibility: openNav ? "visible" : "hidden",
                                    },
                                })}
                            />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() =>
                                signout?.(() => {
                                    // nothing to do here
                                })
                            }>
                            <ListItemIcon sx={theme => ({color: theme.palette.midnightBlue.light})}>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText
                                primary="Sign out"
                                sx={theme => ({
                                    "& .MuiListItemText-primary": {color: theme.palette.text.secondary},
                                })}
                            />
                        </ListItemButton>
                    </List>
                </div>
            </Drawer>
        </Box>
    );
};
