import {z} from "zod";

export const readUrlsSchema = z.object({
    imageUrl: z.string(),
    segmentationsJsonUrl: z.string(),
    heatmapUrl: z.string(),
    posesOverlayUrl: z.string(),
    posesUrl: z.string(),
    posesJsonUrl: z.string(),
    depthUrl: z.string(),
    oldRasterUrl: z.string(),
    evalUrl: z.string(),
});

export type ReadUrls = z.infer<typeof readUrlsSchema>;

export const writeUrlsSchema = z.object({
    segmentationsJsonUrl: z.string(),
    evalJsonUrl: z.string(),
});

export type WriteUrls = z.infer<typeof writeUrlsSchema>;

export const scanSchema = z.object({
    id: z.number(),
    stage: z.string(),
    success: z.number().optional(), // 0 or 1 (deprecated)
    clientId: z.string().nullable(),
    userId: z.number(),
    annotatorId: z.number().nullable(),
    folderName: z.string(),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    gpsCoordinates: z.string(),
    robustGpsCoordinates: z.string().nullable(),
    rampGrade: z.string().nullable(),
    edge: z.string().nullable(),
    failures: z.string().nullable(),
    scanLength: z.number().nullable(),
    estimatedScanLength: z.number().nullable(),
    rescanFolderName: z.string().nullable(),
    gpsMultiline: z.array(z.string()).nullable(),
    adjustedGpsMultiline: z.array(z.string()).nullable(),
    subStage: z.string().nullable(),
    project: z
        .object({
            id: z.number(),
            name: z.string(),
        })
        .nullable(),
    organization: z
        .object({
            id: z.number(),
            name: z.string(),
        })
        .nullable(),
});

export type Scan = z.infer<typeof scanSchema>;

export const regionSchema = z.object({
    panel_id: z.number(),
    label: z.string(),
    shape_type: z.union([z.literal("point"), z.literal("polygon"), z.literal("linestrip")]),
    area: z.number(),
    description: z.string(),
    group_id: z.number().nullable(),
    flags: z.unknown(),
    gps_point: z.tuple([z.number(), z.number()]).nullable(),
    centroid: z.tuple([z.number(), z.number()]).nullable(),
    points: z.array(z.tuple([z.number(), z.number()])),
});

export type Region = z.infer<typeof regionSchema>;
