import {type AxiosResponse} from "axios";
import {z} from "zod";

import {type MapScan, mapScanSchema} from "react_ct/types";
import {type Scan, scanSchema} from "react_ct/types/scan";
import {apiRequest} from ".";

const rasterInfoSchema = z.object({
    imageUrl: z.string().optional(),
    rotatedImageUrl: z.string().optional(),
    segmentationsJsonUrl: z.string().optional(),
    heatmapUrl: z.string().optional(),
    posesOverlayUrl: z.string().optional(),
    posesUrl: z.string().optional(),
    posesJsonUrl: z.string().optional(),
    depthUrl: z.string().optional(),
    oldRasterUrl: z.string().optional(),
    evalUrl: z.string().optional(),
});

export const apiGetScansFromFoldernames = async (
    folderNames: string[],
    includeProjectInformation = false,
): Promise<MapScan[]> => {
    const {data} = await apiRequest({path: `scan/scanId`, params: {folderNames}});
    const scanIds: number[] = data as number[];
    const {data: scanData} = await apiRequest({path: "scan", params: {scanIds, includeProjectInformation}});
    const parsedScans = z.array(mapScanSchema).safeParse(scanData);
    if (!parsedScans.success) {
        console.warn("Zod error: ", parsedScans.error);
        return scanData as MapScan[];
    }
    return parsedScans.data;
};

export type RasterInfoType = z.infer<typeof rasterInfoSchema>;

export const apiGetRasterInfo = async (scanId: number): Promise<RasterInfoType> => {
    const {data} = await apiRequest({
        path: `scan/read-urls`,
        params: {scanId, evalUrl: true},
    });
    const parsedResponse = rasterInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as RasterInfoType;
    }
    return parsedResponse.data;
};

export const apiGetAllReadUrls = async (scanId: number): Promise<RasterInfoType> => {
    const {data} = await apiRequest({
        path: `scan/read-urls`,
        params: {
            scanId,
            imageUrl: true,
            rotatedImageUrl: true,
            segmentationsJsonUrl: true,
            heatmapUrl: true,
            posesOverlayUrl: true,
            posesUrl: true,
            posesJsonUrl: true,
            depthUrl: true,
            oldRasterUrl: true,
            evalUrl: true,
        },
    });

    const parsedResponse = rasterInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as RasterInfoType;
    }
    return parsedResponse.data;
};

export const apiArchiveScan = async (scanId: number): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan?scanId=${scanId}`, method: "PUT", data: {stage: "archive"}});
};

export const putScanStage = async (scanId: number, stage: string): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {stage}});
};

export const putScanSubstage = async (scanId: number, subStage: string | null): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {subStage}});
};

export const updatesScanStageAndSubstage = async (
    scanId: number,
    stage: string,
    subStage: string | null,
): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {stage, subStage}});
};

export const putScanAdjustedMulitline = async (
    scanId: number,
    multiline: Array<[number, number]>,
): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {adjustedGpsMultiline: multiline}});
};

export const getScansByStage = async (stages: string[], includeProjectInformation: boolean): Promise<Scan[]> => {
    if (stages.length === 0) {
        return [];
    }

    const {data} = await apiRequest({
        path: `scan`,
        params: {"stages[]": stages, includeProjectInformation},
    });
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const getScansBySubstage = async (substages: string[], includeProjectInformation: boolean): Promise<Scan[]> => {
    if (substages.length === 0) {
        return [];
    }

    const {data} = await apiRequest({
        path: `scan`,
        params: {"substages[]": substages, includeProjectInformation},
    });
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const getScansByProjectId = async (projectId: number): Promise<Scan[]> => {
    const {data} = await apiRequest({path: `scan`, params: {"projectIds[]": projectId}});
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const getScansByProjectIds = async (projectIds: number[]): Promise<Scan[]> => {
    const {data} = await apiRequest({path: `scan`, params: {"projectIds[]": projectIds}});
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        return data as Scan[];
    }

    return parsedResponse.data;
};
