import {colors} from "react_ct/theme";

const API_TOKEN: string = process.env.REACT_APP_ESRI_API_KEY ?? "";

export const MAP_STYLE = {
    // "street" = "mapbox://styles/lukedeepwalk/clp1rhfng00x001nsb347cki1",
    street: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/items/78022df0f1594bc794caa0aa816987ae?token=${API_TOKEN}`,
    satellite: "mapbox://styles/lukedeepwalk/clp2to72o00yk01qnap46btb3",
};

export type MapStyle = keyof typeof MAP_STYLE;

export const SOURCE_IDS = {
    Polygon: "map_Polygon",
    LineString: "map_LineString",
    Point: "map_Point",
    Symbol: "map_Symbol",
};

export type PaintStyle = "stroke" | "regular" | "selected" | "cluster";
export type LayerIDObject = Record<PaintStyle, string | null>;

export const LAYER_IDS: Record<ShapeType, LayerIDObject> = {
    Point: {
        stroke: null,
        regular: "map_Point-regular",
        selected: "map_Point-selected",
        cluster: "map_Point-cluster",
    },
    LineString: {
        stroke: "map_LineString-stroke",
        regular: "map_LineString-regular",
        selected: "map_LineString-selected",
        cluster: null,
    },
    Polygon: {
        regular: "map_Polygon-regular",
        selected: "map_Polygon-selected",
        stroke: null,
        cluster: null,
    },
    Symbol: {
        regular: "map_Symbol-regular",
        selected: null,
        stroke: null,
        cluster: "map_Symbol-cluster",
    },
};

export type ShapeType = keyof typeof SOURCE_IDS;
export const SHAPE_OPTIONS = ["Point", "LineString", "Polygon"];
export type LayerShapeType = "circle" | "fill" | "symbol" | "line";

export const layers = [
    "Census",
    "POI",
    "Midblock Accessibility",
    "Curb Ramp",
    "Run Slope",
    "Cross Slope",
    "Clear Width",
    "Deterioration Layer",
    "Driveway",
    "Horizontal",
    "Vertical",
    "Trip Hazard",
    "Overhang",
    "Tree Hazard",
    "Hazard",
    "Obstructions",
    "Cross Slope Panel",
];

export const DRAW_STYLES = [
    {
        id: "gl-draw-polygon-fill-drawing",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"], ["!=", "mode", "draw_polygon"]],
        paint: {
            "fill-color": colors.orange,
            "fill-outline-color": colors.orange,
            "fill-opacity": 0.3,
        },
    },
    {
        id: "gl-draw-polygon-stroke-drawing",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"], ["==", "mode", "draw_polygon"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.orange,
            "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-line-drawing",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"], ["==", "mode", "draw_line_string"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.orange,
            "line-width": 2,
            "line-dasharray": [0.2, 2],
        },
    },
    {
        id: "gl-draw-polygon-fill-active",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"], ["==", "mode", "draw_polygon"]],
        paint: {
            "fill-color": colors.orange,
            "fill-outline-color": colors.orange,
            "fill-opacity": 0.1,
        },
    },
    {
        id: "gl-draw-polygon-midpoint-active",
        type: "circle",
        filter: ["all", ["==", "meta", "midpoint"], ["==", "active", "true"]],
        paint: {
            "circle-radius": 5,
            "circle-color": colors.orange,
        },
    },

    {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"], ["!=", "mode", "draw_polygon"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.orange,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-polygon-and-line-vertex-active",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"]],
        paint: {
            "circle-radius": 5,
            "circle-color": colors.orange,
            "circle-stroke-width": 2,
            "circle-stroke-color": "white",
        },
    },
    {
        id: "gl-draw-line-active",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"], ["!=", "mode", "draw_line_string"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.orange,
            "line-width": 2,
        },
    },
    {
        id: "gl-draw-point-static",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "active", "false"]],
        paint: {
            "circle-radius": 3,
            "circle-color": colors.orange,
            "circle-stroke-width": 1,
            "circle-stroke-color": "white",
        },
    },
    {
        id: "gl-draw-line-static",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "false"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.lightBlue,
            "line-width": 3,
        },
    },
    {
        id: "gl-draw-polygon-fill-static",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
        paint: {
            "fill-color": colors.lightBlue,
            "fill-outline-color": colors.lightBlue,
            "fill-opacity": 0.3,
        },
    },
    {
        id: "gl-draw-polygon-stroke-static",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": colors.lightBlue,
            "line-width": 1,
            "line-opacity": 0.5,
        },
    },
];
