import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    TextField,
    Select,
    dialogClasses,
    MenuItem,
    AlertProps,
    SelectChangeEvent,
} from "@mui/material";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {DashboardView} from "../Home";
import {apiRequest} from "react_ct/requests";
import {projectKeys} from "queries/queries";
import {ReportType} from "react_ct/types";

export function SaveDashboardViewModal({
    openSaveModal,
    setOpenSaveModal,
    projectId,
    currentCategory,
    setAlert,
    currentMapFeatures,
    freeSelectRange,
    setCurrentDashboardView,
}: {
    openSaveModal: boolean;
    setOpenSaveModal: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: number;
    currentCategory: string;
    setAlert: React.Dispatch<React.SetStateAction<{message: string; severity: AlertProps["severity"]} | undefined>>;
    currentMapFeatures: Array<GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point, ReportType>>;
    freeSelectRange: GeoJSON.Feature[];
    setCurrentDashboardView: React.Dispatch<React.SetStateAction<DashboardView | undefined>>;
}): React.ReactElement {
    const qc = useQueryClient();
    const saveDashboardTextRef = React.useRef<HTMLInputElement>(null);

    const saveDashboardMutation = useMutation({
        mutationFn: async () => {
            return await apiRequest({
                method: "post",
                path: `project/${projectId}/dashboard`,
                data: {
                    dashboard: {
                        name: saveDashboardTextRef.current?.value,
                        category: currentCategory,
                        scanFeatureIds: currentMapFeatures.map(feature => feature.properties?.id),
                        polygonSelectCoordinates: freeSelectRange.map(
                            feature => (feature.geometry as GeoJSON.Polygon).coordinates,
                        ),
                    },
                },
            });
        },
        onMutate: () => {
            setOpenSaveModal(false);
            setAlert({message: "Saving dashboard view...", severity: "info"});
        },
        onSuccess: data => {
            const savedView: DashboardView = data.data;
            void qc.invalidateQueries({queryKey: projectKeys.projectDashboards(projectId)});
            setAlert({message: "Successfully created dashboard view", severity: "success"});
            setCurrentDashboardView(savedView);
        },
        onError: error => {
            setAlert({message: `Could not create dashboard view: ${error.message}`, severity: "error"});
        },
    });

    return (
        <Dialog
            id="save-dashboard"
            open={openSaveModal}
            sx={{
                width: "67%",
                margin: "auto",
                [`& .${dialogClasses.paper}`]: {
                    width: "100%",
                },
            }}>
            <DialogTitle>Save Dashboard View</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: theme => theme.palette.text.primary, mb: 2}}>
                    Save a dashboard view to have access to statistics for curated groups of features.
                </DialogContentText>
                <TextField label="Name" variant="filled" sx={{width: "67%"}} inputRef={saveDashboardTextRef} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenSaveModal(false)}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        saveDashboardMutation.mutate();
                        setOpenSaveModal(false);
                    }}>
                    Save View
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function OverwriteDashboardViewModal({
    openOverwriteModal,
    setOpenOverwriteModal,
    savedDashboardViews,
    setAlert,
    projectId,
    currentMapFeatures,
    freeSelectRange,
}: {
    openOverwriteModal: boolean;
    setOpenOverwriteModal: React.Dispatch<React.SetStateAction<boolean>>;
    savedDashboardViews: DashboardView[] | undefined;
    setAlert: React.Dispatch<React.SetStateAction<{message: string; severity: AlertProps["severity"]} | undefined>>;
    projectId: number;
    currentMapFeatures: Array<GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point, ReportType>>;
    freeSelectRange: GeoJSON.Feature[];
}): React.ReactElement {
    const qc = useQueryClient();

    const [selectedView, setSelectedView] = React.useState<number>();

    const handleChangeSelectedView = (event: SelectChangeEvent): void => {
        setSelectedView(parseInt(event.target.value));
    };

    React.useEffect(() => {
        if (!openOverwriteModal) {
            setSelectedView(undefined);
        }
    }, [openOverwriteModal]);

    const overwriteDashboardMutation = useMutation({
        mutationFn: async () => {
            await apiRequest({
                method: "put",
                path: `project/${projectId}/dashboard/${selectedView ?? 0}`,
                data: {
                    dashboard: {
                        name: savedDashboardViews?.find(view => view.id === selectedView)?.name,
                        scanFeatureIds: currentMapFeatures.map(feature => feature.properties?.id),
                        polygonSelectCoordinates: freeSelectRange.map(
                            feature => (feature.geometry as GeoJSON.Polygon).coordinates,
                        ),
                    },
                },
            });
        },
        onMutate: () => {
            setAlert({message: "Saving view...", severity: "info"});
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: projectKeys.projectDashboards(projectId)});
            setAlert({message: "Successfully saved dashboard view", severity: "success"});
            setOpenOverwriteModal(false);
        },
        onError: error => {
            setAlert({message: `Could not save dashboard view: ${error.message}`, severity: "error"});
            setOpenOverwriteModal(false);
        },
    });

    return (
        <Dialog
            id="overwrite-dashboard"
            open={openOverwriteModal}
            sx={{
                width: "67%",
                margin: "auto",
                [`& .${dialogClasses.paper}`]: {
                    width: "100%",
                },
            }}>
            <DialogTitle>Overwrite Dashboard View</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: theme => theme.palette.text.primary, mb: 2}}>
                    Overwrite an existing dashboard's features.
                </DialogContentText>
                <FormControl fullWidth>
                    <InputLabel id="list-of-existing-programs-label">Saved Programs</InputLabel>
                    <Select
                        labelId="list-of-existing-programs-label"
                        id="list-of-existing-programs-select"
                        value={String(selectedView)}
                        onChange={handleChangeSelectedView}>
                        {savedDashboardViews?.map(view => (
                            <MenuItem key={view.id} value={view.id}>
                                {view.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenOverwriteModal(false)}>Cancel</Button>
                <Button
                    variant="contained"
                    disabled={!selectedView}
                    onClick={() => {
                        overwriteDashboardMutation.mutate();
                    }}>
                    Overwrite View
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function DeleteDashboardViewModal({
    openDeleteModal,
    setOpenDeleteModal,
    setAlert,
    dashboardToDelete,
    projectId,
}: {
    openDeleteModal: boolean;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAlert: React.Dispatch<React.SetStateAction<{message: string; severity: AlertProps["severity"]} | undefined>>;
    dashboardToDelete: DashboardView;
    projectId: number;
}): React.ReactElement {
    const qc = useQueryClient();

    const deleteDashboardMutation = useMutation({
        mutationFn: async () => {
            await apiRequest({
                method: "delete",
                path: `project/${projectId}/dashboard/${dashboardToDelete.id}`,
            });
        },
        onMutate: () => {
            setAlert({message: `Deleting ${dashboardToDelete.name}...`, severity: "info"});
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: projectKeys.projectDashboards(projectId)});
            setAlert({message: "Successfully deleted dashboard view", severity: "success"});
            setOpenDeleteModal(false);
        },
        onError: error => {
            setAlert({message: `Could not delete dashboard view: ${error.message}`, severity: "error"});
            setOpenDeleteModal(false);
        },
    });

    return (
        <Dialog
            open={openDeleteModal}
            sx={{
                width: "67%",
                margin: "auto",
                [`& .${dialogClasses.paper}`]: {
                    width: "100%",
                },
            }}>
            <DialogTitle>Delete {dashboardToDelete.name}?</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: theme => theme.palette.text.primary, mb: 2}}>
                    Are you sure you want to delete this dashboard view?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        deleteDashboardMutation.mutate();
                    }}>
                    Delete View
                </Button>
            </DialogActions>
        </Dialog>
    );
}
