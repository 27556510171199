import React from "react";
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Button, Container, Stack, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import create_bg from "assets/implement_create_bg.png";

interface SelectProgram {
    name: string;
    icon: React.ReactNode;
    description: string;
    url: string;
    inBeta: boolean;
}

function SelectProgramLink({name, icon, description, url}: SelectProgram): JSX.Element {
    return (
        <Accordion
            sx={{
                my: 2,
                backgroundColor: theme => theme.palette.deepWalkBlue.dark,
                borderRadius: theme => `${theme.shape.borderRadius}px !important`,
            }}>
            <AccordionSummary
                expandIcon={<ExpandMore color="secondary" />}
                sx={{
                    position: "relative",
                }}>
                <Box position="absolute" top={0} left={0} zIndex={1} sx={{opacity: 0.25}}>
                    {icon}
                </Box>
                <Typography color="white" variant="h5" component="h3" position="relative" zIndex={2}>
                    {name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    p: 2,
                }}>
                <Box
                    margin="auto"
                    p={2}
                    borderRadius={theme => theme.shape.borderRadius / 2}
                    sx={{
                        backgroundColor: theme => theme.palette.background.paper,
                    }}>
                    {description}
                </Box>
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" width="100%">
                    <Button variant="contained" color="secondary" href={`create/${url}`} sx={{marginTop: 2}}>
                        Create Program
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default function CreateProgramHome(): JSX.Element {
    const programs: SelectProgram[] = [
        {
            url: "vegetation",
            name: "Obstruction Removal",
            icon: <></>,
            description:
                "Identify locations where the sidewalk is obstructed for removal by maintenance teams or property owners.",
            inBeta: true,
        },
        {
            url: "remove-replace",
            name: "Remove and Replace",
            icon: <></>,
            description:
                "Create work orders for individual deteriorated, sloped, or heaved sidewalk panels for replacement.",
            inBeta: true,
        },
        {
            url: "resurfacing",
            name: "Resurfacing Adjacent Curb Ramps",
            icon: <></>,
            description:
                "Generate cost estimates for curb ramp retrofits for your upcoming roadway resurfacing projects.",
            inBeta: true,
        },
        {
            url: "triphazard",
            name: "Hazard Removal",
            icon: <></>,
            description:
                "Create work orders or bid documents for the removal of hazards via sawcutting, grinding or mudjacking.",
            inBeta: false,
        },
    ];
    return (
        <Box
            id="page-container"
            sx={{
                height: "100vh",
                width: "100%",
                position: "relative",
                backgroundImage: `url(${create_bg})`,
                backgroundSize: "cover",
            }}>
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                sx={{backgroundColor: "rgba(0,0,0,0.6)", backdropFilter: "blur(10px)"}}
            />
            <Container
                sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                }}>
                <Box
                    flexBasis="100%"
                    minHeight="50%"
                    py={2}
                    px={4}
                    borderRadius={theme => theme.shape.borderRadius}
                    boxShadow={theme => theme.shadows[4]}
                    zIndex={2}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                    })}>
                    <Typography variant="h3" component="h2" mb={4}>
                        Create a Program
                    </Typography>
                    <Stack mt={4} width="75%" margin="auto">
                        {programs
                            .filter(program => !program.inBeta)
                            .map(program => (
                                <SelectProgramLink key={program.url} {...program} />
                            ))}
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}
