import {z} from "zod";

const manualTagSQLOutput = z.object({
    id: z.number(),
    name: z.string(),
    definition: z.string(),
    red_normalized: z.number().min(0).max(1),
    green_normalized: z.number().min(0).max(1),
    blue_normalized: z.number().min(0).max(1),
    editable: z.boolean(),
    archived: z.boolean(),
});

export type ManualTagSQLOutput = z.infer<typeof manualTagSQLOutput>;

const manualTagSQLInput = z.object({
    id: z.number().optional(),
    name: z.string().optional(),
    definition: z.string().optional(),
    red_normalized: z.number().min(0).max(1).optional(),
    green_normalized: z.number().min(0).max(1).optional(),
    blue_normalized: z.number().min(0).max(1).optional(),
    editable: z.boolean().optional(),
    archived: z.boolean().optional(),
});

export type ManualTagSQLInput = z.infer<typeof manualTagSQLInput>;

const manualTag = z.object({
    id: z.number().optional(),
    name: z.string(),
    definition: z.string(),
    color: z.string().optional(),
    editable: z.boolean(),
    archived: z.boolean().optional(),
});

export type ManualTag = z.infer<typeof manualTag>;

export const manualTagDataSchema = z.object({
    scanId: z.number(),
    metadata: z.object({
        id: z.number().nullable(),
        name: z.string(),
    }),
    s3Url: z.string(),
    rasterPosition: z.array(z.number()),
    pointCloudPosition: z.array(z.number()),
});

export type ManualTagData = z.infer<typeof manualTagDataSchema>;
