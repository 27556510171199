import {z} from "zod";

import {
    type Project,
    projectSchema,
    type ProjectUser,
    projectUserSchema,
    cleanScanSchema,
    type CleanScan,
    type ProjectAnnotatorScanInfo,
    type ProjectScanInfo,
    projectAnnotatorScanInfoSchema,
    projectScanInfoSchema,
} from "react_ct/types";
import {apiRequest} from ".";

export const getAllProjects = async (archived = false, completed = false): Promise<Project[]> => {
    const {data} = await apiRequest({path: `projects`, method: "GET", params: {archived, completed}});
    const parsedResponse = z.array(projectSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as Project[];
    }

    return parsedResponse.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectMileageDetailed = async (projectId: number): Promise<any> => {
    const {data} = await apiRequest({path: `project/${projectId}/mileage/detailed`});
    return data;
};

export const getProjectStageInfo = async (projectId: number): Promise<ProjectScanInfo> => {
    const {data} = await apiRequest({path: `project/${projectId}/stage-info`});
    const parsedResponse = projectScanInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data;
    }

    return parsedResponse.data;
};

export const getProjectAnnotatorStageInfo = async (projectId: number): Promise<ProjectAnnotatorScanInfo> => {
    const {data} = await apiRequest({path: `project/${projectId}/annotator-stage-info`});
    const parsedResponse = projectAnnotatorScanInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data;
    }

    return parsedResponse.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectScans = async (projectId: number): Promise<any> => {
    const body = {
        projectIds: [projectId],
    };

    const {data} = await apiRequest({
        path: `scans`,
        method: "GET",
        data: body,
    });

    return data;
};

export const updateProjectsBatchPermissions = async (
    projects: Array<{id: number; approvedForBatchLabeling: boolean}>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
    const {data} = await apiRequest({
        path: `projects`,
        method: "PUT",
        data: {projects},
    });

    return data;
};

export const apiGetMyProjects = async (): Promise<Project[]> => {
    const {data} = await apiRequest({path: `project/project-info/me`});
    const parsedResponse = z.array(projectSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        // throw new Error("Invalid response");
        return data as Project[]; // return not-type-safe data so that the app doesn't crash
    }
    return parsedResponse.data;
};

export const getProjectUsers = async (projectId?: number): Promise<ProjectUser[]> => {
    if (!projectId) {
        return [];
    }
    const {data} = await apiRequest({path: `project/${projectId}/members`});
    const parsedResponse = z.array(projectUserSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        // throw new Error("Invalid response");
        return data as ProjectUser[]; // return not-type-safe data so that the app doesn't crash
    }
    return parsedResponse.data;
};

export const getCleanProjectScans = async (projectId: number): Promise<CleanScan[]> => {
    const {data} = await apiRequest({
        path: `scan/clean`,
        params: {
            projectIds: [projectId],
        },
    });
    const parsedResponse = z.array(cleanScanSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        throw new Error(`Invalid response: ${parsedResponse.error.message}`);
    }
    return parsedResponse.data;
};

export const removeProjectMember = async (projectId: number, userId: number): Promise<void> => {
    await apiRequest({
        path: `project/${projectId}/members/${userId}`,
        method: "DELETE",
    });
};

export const removeProjectMemberCurried = (projectId: number) => {
    return async (userId: number): Promise<void> => {
        return await removeProjectMember(projectId, userId);
    };
};
