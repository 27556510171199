import {type Webmap} from "react_ct/types";
import {apiRequest} from ".";
import {request} from "@esri/arcgis-rest-request";

export const createWebmap = async (projectId: number, userId: number, esriToken: string): Promise<void> => {
    await apiRequest({
        path: `webmap`,
        method: "POST",
        data: {userId, esriToken, projectId},
    });
};

export const getWebmaps = async (projectId: number): Promise<Webmap[]> => {
    const {data} = await apiRequest({
        path: `webmap`,
        method: "GET",
        params: {projectId},
    });

    return data;
};

export const deleteWebmap = async (webmapId: number, projectId: number): Promise<void> => {
    await apiRequest({
        path: `webmap/${webmapId}`,
        method: "DELETE",
        params: {projectId},
    });
};

export const downloadFromS3 = async (url: string): Promise<Response> => {
    return await fetch(url);
};

export const apiReverseGeocode = async (location: {x: number; y: number}): Promise<string> => {
    const esriToken = process.env.REACT_APP_ESRI_API_KEY;
    try {
        const response = await request(
            `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode`,
            {
                params: {
                    location: `${location.x},${location.y}`,
                    token: esriToken,
                    featureTypes: "StreetAddress",
                },
            },
        );
        return response.address?.ShortLabel ? String(response.address.ShortLabel) : `(${location.x}, ${location.y})`;
    } catch (error) {
        console.error(error);
        return `(${location.x}, ${location.y})`;
    }
};
