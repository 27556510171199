import React from "react";
import {
    Alert,
    type AlertColor,
    Box,
    Fab,
    Snackbar,
    Tooltip,
    Stack,
    Button,
    styled,
    Paper,
    FormGroup,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel,
    DialogActions,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import DWMap from "components/Map/DWMap";
import {CheckCircleOutline, ErrorOutline, Save, Upload} from "@mui/icons-material";
import {apiRequest} from "react_ct/requests";
import {useProject} from "contexts/ProjectContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createGeoJSONFeatureCollection} from "components/Map/helpers";
import {colors} from "react_ct/theme";
import LoadingScreen from "components/LoadingScreen";
import {ShiftControl} from "components/Map/MapComponents";
import LoadingError from "components/LoadingError";
import {isEqual} from "lodash";
import {useBlocker} from "react-router-dom";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

function UploadGeoJSONModal({
    geojson,
    open,
    onClose,
    onUploadGeojson,
}: {
    geojson: GeoJSON.FeatureCollection | undefined;
    open: boolean;
    onClose: () => void;
    onUploadGeojson: (option: "add" | "overwrite", geojson: GeoJSON.FeatureCollection) => void;
}): React.ReactElement {
    const [selectedOption, setSelectedOption] = React.useState<"add" | "overwrite">("add");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedOption((event.target as HTMLInputElement).value as "add" | "overwrite");
    };
    return (
        <Dialog open={open && !!geojson} {...{onClose}} aria-labelledby="upload-geojson-title">
            <DialogTitle id="upload-geojson-title">Upload GeoJSON File</DialogTitle>
            <DialogContent>
                <Typography>View your uploaded GeoJSON file:</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    maxRows={8}
                    value={JSON.stringify(geojson ?? "").replaceAll('"', "'")}
                />
                <FormControl sx={{mt: 2}}>
                    <FormLabel>Upload Options</FormLabel>
                    <RadioGroup value={selectedOption} onChange={handleChange}>
                        <FormControlLabel label="Overwrite existing plan" value="overwrite" control={<Radio />} />
                        <FormControlLabel label="Add to existing plan" value="add" control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => onUploadGeojson(selectedOption, geojson as GeoJSON.FeatureCollection)}>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function Plan(): React.ReactElement {
    const {currentProject, projectScans: scans, projectScansError} = useProject();
    const queryClient = useQueryClient();

    const [drawFeatures, setDrawFeatures] = React.useState<GeoJSON.FeatureCollection>({
        type: "FeatureCollection",
        features: [],
    });
    const [isShiftPressed, setIsShiftPressed] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<{severity: AlertColor; message: string}>();
    const [showOriginalLines, setShowOriginalLines] = React.useState(true);
    const [openUploadModal, setOpenUploadModal] = React.useState(false);
    const [uploadedGeoJSON, setUploadedGeoJSON] = React.useState<GeoJSON.FeatureCollection>();
    const [isLoadingGeojson, setIsLoadingGeojson] = React.useState(false);

    const uploadGeojsonRef = React.useRef<HTMLInputElement>(null);

    const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setShowOriginalLines(event.target.checked);
    };

    const data = React.useMemo(
        () =>
            scans
                ? createGeoJSONFeatureCollection(scans, () => colors.gray)
                : {type: "FeatureCollection", features: []},
        [scans],
    );

    const {data: savedFeatures, error: savedFeaturesError} = useQuery({
        queryKey: ["getSavedFeatures", currentProject?.id],
        queryFn: async () => {
            if (currentProject) {
                const res = await apiRequest({
                    path: `project/plan/${currentProject.id}/me`,
                });
                const collection: GeoJSON.FeatureCollection = res.data.collection?.features;

                return (
                    collection ?? {
                        type: "FeatureCollection",
                        features: [],
                    }
                );
            }
        },
        enabled: !!currentProject,
    });

    const openSnackbar = Boolean(snackbarMessage);

    const handleUploadGeoJSON = (event: React.ChangeEvent): void => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = event => {
                // process the geojson contents
                setIsLoadingGeojson(false);
                if (event.target?.result) {
                    let content = event.target?.result;
                    const decoder = new TextDecoder();
                    if (content instanceof ArrayBuffer) content = decoder.decode(content);
                    const geojson: GeoJSON.FeatureCollection = JSON.parse(content);
                    setUploadedGeoJSON(geojson);
                    setOpenUploadModal(true);
                }
            };
            reader.onprogress = () => {
                setIsLoadingGeojson(true);
            };
            reader.onerror = () => {
                setIsLoadingGeojson(false);
                setSnackbarMessage({
                    severity: "error",
                    message: "Could not parse the given file.",
                });
            };
        }
    };

    const onUploadGeojson = (option: "add" | "overwrite", geojson: GeoJSON.FeatureCollection): void => {
        if (option === "add") {
            setDrawFeatures(prev => ({
                type: "FeatureCollection",
                features: [...prev.features, ...geojson.features],
            }));
        } else {
            setDrawFeatures({type: "FeatureCollection", features: geojson.features});
        }
        setOpenUploadModal(false);
        setSnackbarMessage({
            severity: "success",
            message: "Successfully uploaded GeoJSON file.",
        });
        if (uploadGeojsonRef.current) uploadGeojsonRef.current.value = "";
    };

    const hasUnsavedChanges = React.useMemo(() => {
        if (drawFeatures.features.length !== savedFeatures?.features.length) {
            return true;
        }

        // Compare each element
        for (let i = 0; i < drawFeatures.features.length; i++) {
            if (drawFeatures.features[i].id !== savedFeatures.features[i].id) {
                return true;
            } else if (!isEqual(drawFeatures.features[i].geometry, savedFeatures.features[i].geometry)) {
                return true;
            }
        }

        return false;
    }, [savedFeatures, drawFeatures]);

    React.useEffect(() => {
        if (!openUploadModal) setUploadedGeoJSON(undefined);
    }, [openUploadModal]);

    React.useEffect(() => {
        if (savedFeatures) setDrawFeatures(savedFeatures);
    }, [savedFeatures]);

    const saveChangesMutation = useMutation({
        mutationFn: async () => {
            if (!currentProject) throw new Error("Could not retrieve current project");
            return await apiRequest({
                method: "post",
                path: `project/plan/${currentProject.id}/me`,
                data: {collection: drawFeatures},
            });
        },
        onSuccess: async () => {
            void queryClient.invalidateQueries({queryKey: ["getSavedFeatures", currentProject?.id]});
            setSnackbarMessage({
                severity: "success",
                message: "Changes saved successfully!",
            });
            if (blocker.state === "blocked" && blocker.proceed) blocker.proceed();
        },
        onError: async error => {
            setSnackbarMessage({
                severity: "error",
                message: `Could not save changes: ${error.message.includes("413") ? "" : error.message}`,
            });
            if (blocker.state === "blocked" && blocker.reset) blocker.reset();
        },
    });

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") setIsShiftPressed(true);
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        return currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges;
    });

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") setIsShiftPressed(true);
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    return (
        <Box id="page-container" width="100%" height="100vh" position="relative">
            {(projectScansError ?? savedFeaturesError) ? (
                <LoadingError errorMessage={projectScansError ?? savedFeaturesError?.message} />
            ) : scans && savedFeatures ? (
                <>
                    <Backdrop sx={theme => ({color: "#fff", zIndex: theme.zIndex.drawer + 1})} open={isLoadingGeojson}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <BeforeUnloadDialog
                        {...{blocker}}
                        onSave={() => {
                            saveChangesMutation.mutate();
                        }}
                        onLeave={() => {
                            if (blocker.proceed) blocker.proceed();
                        }}
                    />
                    <UploadGeoJSONModal
                        geojson={uploadedGeoJSON}
                        open={openUploadModal}
                        onClose={() => {
                            setOpenUploadModal(false);
                            if (uploadGeojsonRef.current) uploadGeojsonRef.current.value = "";
                        }}
                        {...{onUploadGeojson}}
                    />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={5000}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        onClose={() => {
                            setSnackbarMessage(undefined);
                        }}>
                        <Alert
                            severity={snackbarMessage?.severity}
                            icon={snackbarMessage?.severity === "success" ? <CheckCircleOutline /> : <ErrorOutline />}>
                            {snackbarMessage?.message}
                        </Alert>
                    </Snackbar>
                    <DWMap
                        drawControl
                        satelliteControl
                        multiSelect
                        onMultiSelect={() => {
                            // idk
                        }}
                        dataId="id"
                        selectedFeature={undefined}
                        zoomOnClick={false}
                        data={
                            showOriginalLines
                                ? (data as GeoJSON.FeatureCollection)
                                : {type: "FeatureCollection", features: []}
                        }
                        {...{drawFeatures, setDrawFeatures}}
                    />
                    <ShiftControl isShiftPressed={isShiftPressed} top={8} left={24} />
                    <Paper
                        elevation={3}
                        sx={{
                            position: "absolute",
                            top: 64,
                            left: 24,
                            zIndex: 20,
                            borderRadius: theme => theme.shape.borderRadius,
                            px: 2,
                        }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={showOriginalLines} onChange={handleToggleSwitch} />}
                                label="Show original scan lines"
                            />
                        </FormGroup>
                    </Paper>
                    <Stack
                        gap={2}
                        alignItems="center"
                        sx={{
                            position: "absolute",
                            bottom: 32,
                            right: 32,
                        }}>
                        <Button variant="contained" color="secondary" component="label" startIcon={<Upload />}>
                            Upload GeoJSON File
                            <VisuallyHiddenInput
                                type="file"
                                accept="application/geo+json"
                                ref={uploadGeojsonRef}
                                onChange={handleUploadGeoJSON}
                            />
                        </Button>
                        <Tooltip title="Save plans">
                            <span>
                                <Fab
                                    color="primary"
                                    aria-label="save"
                                    sx={{
                                        p: 2,
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    disabled={!hasUnsavedChanges}
                                    onClick={() => saveChangesMutation.mutate()}>
                                    <Save sx={{fontSize: "4rem"}} />
                                </Fab>
                            </span>
                        </Tooltip>
                    </Stack>
                </>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
