import React, {useState} from "react";

import {
    Box,
    Button,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import PolylineRoundedIcon from "@mui/icons-material/PolylineRounded";
import ScatterPlotRoundedIcon from "@mui/icons-material/ScatterPlotRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";

import street_image from "./assets/street.png";
import satellite_image from "./assets/satellite.png";
import {Delete, FormatShapes, PanTool, PentagonOutlined, ShowChart} from "@mui/icons-material";
import type MapboxDraw from "@mapbox/mapbox-gl-draw";

interface SatelliteButtonProps {
    onClick: () => void;
    showSatellite: boolean;
    size?: "sm" | "lg";
}

export const ToggleSatelliteButton: React.FC<SatelliteButtonProps> = props => {
    const clamp = props.size === "lg" ? "clamp(75px, 15%, 300px)" : "clamp(50px, 10%, 200px)";

    return (
        <Box
            sx={theme => ({
                width: clamp,
                aspectRatio: "35/37",
                position: "absolute",
                bottom: 0,
                mb: 4,
                ml: `clamp(5px, 2%, 40px)`,
                zIndex: 10,
                display: "inline-flex",
                alignItems: "center",
                borderRadius: "5px",
                border: `5px solid ${theme.palette.secondary.main}`,
                backgroundColor: theme.palette.secondary.main,
                boxShadow: theme.shadows[2],
                userSelect: "none",
                cursor: "pointer",
                transition: theme.transitions.create("transform", {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.shortest,
                }),
                "&:hover": {
                    transform: "scale(1.05)",
                },
            })}
            onClick={props.onClick}>
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient(transparent, #00000066)",
                    zIndex: 9,
                }}></div>
            <img
                src={props.showSatellite ? street_image : satellite_image}
                alt="map toggle"
                style={{borderRadius: "5px", width: "100%", height: "100%"}}
            />
        </Box>
    );
};

export type AnnotationOption = "polylines" | "circles" | "all";

interface AnnotationsToggleProps {
    annotationOption: AnnotationOption;
    setAnnotationOption: (option: AnnotationOption) => void;
}

export const useAnnotationsToggle = (): AnnotationsToggleProps => {
    const [annotationOption, setAnnotationOption] = useState<AnnotationOption>("all");

    return {
        annotationOption,
        setAnnotationOption,
    };
};

export const AnnotationsToggle: React.FC<AnnotationsToggleProps> = (props: AnnotationsToggleProps) => {
    const theme = useTheme();

    const {annotationOption, setAnnotationOption} = props;

    return (
        <ToggleButtonGroup
            value={annotationOption}
            exclusive
            onChange={(event, newAnnotationOption) => {
                if (newAnnotationOption) {
                    setAnnotationOption(newAnnotationOption);
                }
            }}
            sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                mb: 4,
                mr: 3,
                zIndex: 10,
                boxShadow: theme.shadows[2],
            }}>
            <ToggleButton
                value="all"
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: "none",

                    "&.Mui-selected": {
                        backgroundColor: theme.palette.deepWalkBlue.light,
                    },
                }}>
                <PolylineRoundedIcon />
            </ToggleButton>
            <ToggleButton
                value="circles"
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: "none",

                    "&.Mui-selected": {
                        backgroundColor: theme.palette.deepWalkBlue.light,
                    },
                }}>
                <ScatterPlotRoundedIcon />
            </ToggleButton>
            <ToggleButton
                value="polylines"
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: "none",

                    "&.Mui-selected": {
                        backgroundColor: theme.palette.deepWalkBlue.light,
                    },
                }}>
                <ShowChartRoundedIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export function PolygonSelect({
    freeSelectMode,
    setFreeSelectMode,
}: {
    freeSelectMode: "idle" | "editing" | "drawing";
    setFreeSelectMode: React.Dispatch<React.SetStateAction<"idle" | "editing" | "drawing">>;
}): React.ReactElement {
    return (
        <Box position="absolute" top={0} right={0} zIndex={10} mr={2} mt={2}>
            <Tooltip title="Select features" placement="left" arrow>
                <IconButton
                    onClick={() => {
                        if (freeSelectMode === "drawing") {
                            setFreeSelectMode("idle");
                        } else {
                            setFreeSelectMode("drawing");
                        }
                    }}
                    sx={theme => ({
                        backgroundColor:
                            freeSelectMode === "drawing"
                                ? theme.palette.deepWalkBlue.light + "55"
                                : theme.palette.background.paper,
                        boxShadow: theme.shadows[2],
                        borderRadius: theme.shape.borderRadius / 2,
                    })}>
                    <FormatShapes />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export function DrawOptions({
    drawMode,
    changeDrawMode,
    drawer,
    drawSelectedFeatures,
    setDrawFeatures,
}: {
    drawMode: MapboxDraw.DrawMode;
    changeDrawMode: (val: MapboxDraw.DrawMode) => void;
    drawer: MapboxDraw | null;
    drawSelectedFeatures: string[] | undefined;
    setDrawFeatures:
        | ((val: GeoJSON.FeatureCollection | ((prev: GeoJSON.FeatureCollection) => GeoJSON.FeatureCollection)) => void)
        | undefined;
}): React.ReactElement {
    const handleChange = (e: React.MouseEvent<HTMLElement>, value: MapboxDraw.DrawMode): void => {
        if (value !== null) changeDrawMode(value);
    };
    return (
        <Box position="absolute" top={0} right={0} zIndex={10} mr={2} mt={2}>
            <Stack gap={2}>
                <ToggleButtonGroup
                    exclusive
                    value={drawMode}
                    orientation="vertical"
                    color="primary"
                    onChange={handleChange}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: theme.shadows[2],
                        border: "none",
                    })}>
                    <Tooltip title="Select" placement="left" arrow>
                        <ToggleButton
                            value="simple_select"
                            sx={{
                                backgroundColor: theme =>
                                    drawMode === "direct_select" || drawMode === "simple_select"
                                        ? theme.palette.deepWalkBlue.light + "55"
                                        : "transparent",
                                border: "none",
                            }}>
                            <PanTool fontSize="large" />
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title="Polygon" placement="left" arrow>
                        <ToggleButton
                            value="draw_polygon"
                            sx={{
                                backgroundColor: theme =>
                                    drawMode === "draw_polygon"
                                        ? theme.palette.deepWalkBlue.light + "55"
                                        : "transparent",
                                border: "none",
                                borderTop: "1px solid #e0e2fe",
                                borderBottom: "1px solid #e0e2fe",
                            }}>
                            <PentagonOutlined fontSize="large" />
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title="Line" placement="left" arrow>
                        <ToggleButton
                            value="draw_line_string"
                            sx={{
                                backgroundColor: theme =>
                                    drawMode === "draw_line_string"
                                        ? theme.palette.deepWalkBlue.light + "55"
                                        : "transparent",
                                border: "none",
                            }}>
                            <ShowChart fontSize="large" />
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: theme => theme.palette.background.paper,
                        "&:hover": {
                            backgroundColor: "#e0e2fe",
                        },
                    }}
                    disabled={!drawSelectedFeatures || drawSelectedFeatures.length === 0}
                    onClick={() => {
                        if (drawer && drawSelectedFeatures?.length && drawSelectedFeatures?.length > 0) {
                            drawer.delete(drawSelectedFeatures);
                            const remainingFeatures = drawer.getAll();
                            setDrawFeatures?.(prev => ({
                                ...prev,
                                features: remainingFeatures.features,
                            }));
                        }
                    }}>
                    <Delete fontSize="large" sx={{color: "rgba(0, 0, 0, 0.54)"}} />
                </Button>
            </Stack>
        </Box>
    );
}

export function ShiftControl({
    isShiftPressed,
    top,
    right,
    bottom,
    left,
    scale = 1,
}: {
    isShiftPressed: boolean;
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    scale?: number;
}): React.ReactElement {
    return (
        <Box position="absolute" zIndex={5} {...{top, right, bottom, left}} sx={{transform: `scale(${scale})`}}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                    sx={{
                        position: "relative",
                        boxShadow: `0px 1px 4px #00000088${isShiftPressed ? ", inset 0px 2px 4px #00000022" : ""}`,
                        borderRadius: theme => theme.shape.borderRadius / 4,
                        background: theme =>
                            isShiftPressed
                                ? theme.palette.grey[300] + "ef"
                                : `linear-gradient(${theme.palette.background.paper + "ef"} 85%, ${
                                      theme.palette.grey[300] + "ef"
                                  } 85%)`,
                        boxSizing: "border-box",
                        width: "67px",
                        height: "34px",
                        px: 2,
                        pb: 1,
                        backdropFilter: "blur(5px)",
                    }}>
                    <Typography
                        component="kbd"
                        fontWeight={700}
                        sx={{
                            position: "absolute",
                            userSelect: "none",
                            color: theme => (isShiftPressed ? theme.palette.grey[600] : theme.palette.grey[500]),
                            boxSizing: "border-box",
                            mt: isShiftPressed ? "1px" : 0,
                        }}>
                        Shift
                    </Typography>
                </Box>
                <Typography
                    fontWeight={700}
                    fontSize="1.25rem"
                    color="secondary"
                    sx={{textShadow: "0px 1px 4px #000000dd", userSelect: "none"}}>
                    + drag to select multiple points
                </Typography>
            </Stack>
        </Box>
    );
}
