import {z} from "zod";
import {ProjectUser} from ".";

export enum Features {
    tree_hazard = "Tree Hazard",
    overhang = "Overhang",
    utility_box = "Utility Box",
    vertical = "Vertical",
    horizontal = "Horizontal",
    driveway = "Driveway",
    deterioration = "Deterioration",
}

export type FeatureType = `${Features}`;

export enum ViolationSeverity {
    extreme = "extreme",
    severe = "severe",
    moderate = "moderate",
    minor = "minor",
}

export type SeverityType = `${ViolationSeverity}`;

export const panelSchema = z.object({
    panel_id: z.number(),
    global_region_id: z.number(),
    label: z.number(),
    runslope: z.number(),
    crossslope: z.number(),
    width: z.number(),
    area: z.number(),
    centroid: z.tuple([z.number(), z.number()]),
    violations: z.object({
        clear_width: z.nativeEnum(ViolationSeverity).optional(),
        cross_slope: z.nativeEnum(ViolationSeverity).optional(),
        run_slope: z.nativeEnum(ViolationSeverity).optional(),
    }),
});

export type Panel = z.infer<typeof panelSchema>;

export const violationsSchema = z.object({
    cross_slope: z.object({
        extreme: z.number(),
        severe: z.number(),
        moderate: z.number(),
        minor: z.number(),
        SENTINEL: z.number(),
    }),
    run_slope: z.object({
        extreme: z.number(),
        severe: z.number(),
        moderate: z.number(),
        minor: z.number(),
        SENTINEL: z.number(),
    }),
    clear_width: z.object({
        extreme: z.number(),
        severe: z.number(),
        moderate: z.number(),
        minor: z.number(),
        SENTINEL: z.number(),
    }),
    vert_disps: z.object({
        SENTINEL: z.number(),
        manual: z.number(),
    }),
});

export type Violations = z.infer<typeof violationsSchema>;

export const rawScoreSchema = z.object({
    cs_score: z.number(),
    rs_score: z.number(),
    width_score: z.number(),
    dw_score: z.number(),
    percent_det: z.number(),
    ramp_check: z.boolean(),
});

export type RawScore = z.infer<typeof rawScoreSchema>;

export const mediaLinksSchema = z.object({
    labelled_img: z.string(),
    raster_downsampled: z.string(),
    AutoReport: z.string(),
    pointcloud_las: z.string(),
});

export type MediaLinks = z.infer<typeof mediaLinksSchema>;

export type MapFeature = ReportType | MapScan;

export const jsonStringMetadataSchema = z.object({
    id: z.number(),
    collector: z.number(),
    date: z.string(),
    location: z.tuple([z.number(), z.number()]),
    length: z.number(),
    scale: z.number(),
});

export type JsonStringMetadata = z.infer<typeof jsonStringMetadataSchema>;

export const jsonStringSchema = z.object({
    metadata: jsonStringMetadataSchema,
    images: z.object({
        orthoimage: z.string(),
        label_image: z.string().nullable(),
    }),
    vert_disps: z.array(z.unknown()),
    panels: z.string(),
    dw: z.string(),
    noRamp: z.boolean(),
    mileage_path: z.array(z.array(z.array(z.number()))),
    scale_factor: z.number(),
    regions: z.string(),
    areas: z.object({
        Deteriorated: z.number(),
        Panel: z.number(),
        DW: z.number(),
        Brick: z.number(),
        Driveway: z.number(),
        Gutter: z.number(),
        Asphalt: z.number(),
        Gravel: z.number(),
        Overgrown: z.number(),
        Cracked: z.number(),
        Obstruction: z.number(),
        Stairs: z.number(),
        combined: z.number(),
    }),
    violations: violationsSchema,
    raw_scores: rawScoreSchema,
    access_score: z.number(),
    condition_score: z.number(),
    version: z.string(),
    media_links: mediaLinksSchema,
    gpsMultiline: z.array(z.array(z.number())),
    stairs: z.boolean(),
    access_scores: z.object({
        accessibility_grade: z.number(),
        fix_widths: z.number(),
        fix_vd: z.number(),
        fix_dw: z.number(),
        fix_fruit: z.number(),
    }),
});

export type JsonString = z.infer<typeof jsonStringSchema>;

export const autoReportTypeSchema = z.object({
    metadata: z.object({
        id: z.number(),
        collector: z.number(),
        date: z.string(),
    }),
    images: z.object({
        orthoimage: z.string(),
        label_image: z.string().nullable(),
    }),
    vert_disps: z.array(z.unknown()),
    panels: z.string(),
    dw: z.string(),
    noRamp: z.boolean(),
    mileage_path: z.array(z.array(z.array(z.number()))),
    scale_factor: z.number(),
    regions: z.string(),
    areas: z.object({
        Deteriorated: z.number(),
        Panel: z.number(),
        DW: z.number(),
        Brick: z.number(),
        Driveway: z.number(),
        Gutter: z.number(),
        Asphalt: z.number(),
        Gravel: z.number(),
        Overgrown: z.number(),
        Cracked: z.number(),
        Obstruction: z.number(),
        Stairs: z.number(),
        combined: z.number(),
    }),
    violations: violationsSchema,
    raw_scores: rawScoreSchema,
    access_score: z.number(),
    condition_score: z.number(),
    version: z.string(),
    media_links: mediaLinksSchema,
    gpsMultiline: z.array(z.array(z.number())),
    stairs: z.boolean(),
    access_scores: z.object({
        accessibility_grade: z.number(),
        fix_widths: z.number(),
        fix_vd: z.number(),
        fix_dw: z.number(),
        fix_fruit: z.number(),
    }),
    raster_media: z.string(),
});

export type AutoReportType = z.infer<typeof autoReportTypeSchema>;

export const autoReportMediaSchema = z.object({
    labelled_img: z.string(),
    raster_downsampled: z.string(),
    AutoReport: z.string(),
    pointcloud_las: z.string(),
});

export type AutoReportMedia = z.infer<typeof autoReportMediaSchema>;

export const reportTypeSchema = z.object({
    id: z.number(),
    accessibility_grade: z.number(),
    access_description: z.string(),
    vd_fix: z.number(),
    dw_fix: z.number(),
    width_fix: z.number(),
    fruit_fix: z.number(),
    stairs: z.boolean(),
    raw_score: z.object({
        cs_score: z.number(),
        rs_score: z.number(),
        width_score: z.number(),
        dw_score: z.number(),
        percent_det: z.number(),
        ramp_check: z.boolean(),
    }),
    cs_score: z.number(),
    width_score: z.number(),
    rs_score: z.number(),
    max_run_slope: z.number(),
    max_cross_slope: z.number(),
    avg_width: z.number(),
    avg_cross_slope: z.number(),
    avg_run_slope: z.number(),
    panels: z.string(),
    violations: z.string(),
    count_vertical_displacement: z.number(),
    min_clear_width: z.number(),
    detectable_warning: z.string(),
    extreme_obstructions: z.number(),
    severe_obstructions: z.number(),
    passing_space: z.string(),
    autoreport_media: z.string(),
    labelled_img_path: z.string(),
    raster_path: z.string(),
    percent_deteriorated: z.number(),
    labelled_img_media: z.string(),
    raster_media: z.string(),
    report_path: z.string(),
    clear_width_violations: z.number(),
    cross_slope_violations: z.number(),
    run_slope_violations: z.number(),
    vertical_displacement_violations: z.number(),
    type: z.string(),
    material: z.string(),
    collector: z.number(),
    date: z.string(),
    json_string: z.string(),
    length: z.number(),
    Deteriorated_area: z.number(),
    Total_Deteriorated_Area_area: z.number(),
    Panel_area: z.number(),
    DW_area: z.number(),
    Brick_area: z.number(),
    Driveway_area: z.number(),
    Gutter_area: z.number(),
    Asphalt_area: z.number(),
    Gravel_area: z.number(),
    Overgrown_area: z.number(),
    Cracked_area: z.number(),
    Obstruction_area: z.number(),
    Stairs_area: z.number(),
    combined_area: z.number(),
    max_severity: z.string(),
});

export const panelFeatureSchema = z.object({
    centroid: z.array(z.number()).length(2),
    max_severity: z.string(),
    scan_id: z.number(),
    type: z.string(),
});

export type PanelFeature = z.infer<typeof panelFeatureSchema>;

export type IndividualFeatureProperties = z.infer<typeof indvFeatureSchema>;

export const indvFeatureSchema = z.object({
    id: z.string(),
    type: z.string(),
    labelled_img_media: z.string().url(),
    raster_media: z.string().url(),
    cropped_media: z.string().url(),
    folder: z.number(),
    scan_id: z.number(),
    feature_name: z.string(),
    google_maps: z.string().url(),
    address: z.string().optional(),
    color: z.string().optional(),
});

export type ReportType = z.infer<typeof reportTypeSchema>;

export const programFeatureSchema = z.object({
    id: z.number(),
    address: z.string().optional(),
    gpsCoordinate: z.array(z.number()).length(2),
    featureType: z.string(),
    severity: z.string(),
    scanId: z.number(),
    reviewed: z.boolean().optional(),
    accepted: z.boolean().optional(),
    fpv_media: z.string().optional(),
    labelled_img_media: z.string().optional(),
});

export type ImplementProgramFeature = z.infer<typeof programFeatureSchema>;

export const implementProgramSchema = z.object({
    id: z.number(),
    name: z.string(),
    programType: z.string(),
    features: z.array(programFeatureSchema),
    isDraft: z.boolean().optional(),
});

export type ImplementProgram = z.infer<typeof implementProgramSchema>;

export const indvPanelFeatureSchema = z.object({
    area: z.number(),
    centroid: z.array(z.number()).length(2),
    crossslope: z.number(),
    flags: z.array(z.string()),
    global_region_id: z.number(),
    has_cracks: z.boolean(),
    has_deterioration: z.boolean(),
    has_driveway: z.boolean(),
    label: z.string(),
    points: z.array(z.array(z.number()).length(2)),
    runslope: z.number(),
    violations: z.object({
        clear_width: z.string().optional(),
        cross_slope: z.string().optional(),
        run_slope: z.string().optional(),
    }),
    width: z.number(),
});

export type IndividualPanelFeature = z.infer<typeof indvPanelFeatureSchema>;

export const mapScanSchema = z.object({
    id: z.number(),
    scannerId: z.number(), // userId
    lat: z.number(),
    lng: z.number(),
    folderName: z.string(),
    createdAt: z.number(), // epoch
    stage: z.string(),
    scanLength: z.number().nullable(),
    estimatedScanLength: z.number().nullable(),
    gpsMultiline: z.array(z.array(z.number())).nullable(),
    adjustedGpsMultiline: z.array(z.array(z.number())).nullable(),
    gpsCoordinates: z.any().nullable(),
});

export type MapScan = z.infer<typeof mapScanSchema>;
export type CleanMapScan = Omit<Omit<MapScan, "estimatedScanLength">, "scanLength"> & {
    scanLength: number;
    scanLengthConfirmed: boolean;
};

export const cleanScanSchema = z.object({
    id: z.number(),
    userId: z.number(),
    userEmail: z.string(),
    folderName: z.string(),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    gpsCoordinates: z.string().nullable(),
    robustGpsCoordinates: z.string().nullable(),
    gpsMultiline: z.array(z.array(z.number())).nullable(),
    adjustedGpsMultiline: z.array(z.array(z.number())).nullable(),
    stage: z.string(),
    scanLength: z.number(),
    scanLengthConfirmed: z.boolean(),
});

export type CleanScan = z.infer<typeof cleanScanSchema>;

// map bounds: west, south, east, north (see https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatboundslike)

export type MapBounds = number[];

export const materials = [
    "asphalt",
    "brick",
    "concrete",
    "cracked",
    "deteriorated",
    "driveway",
    "gravel",
    "gutter",
    "obstructed",
    "stairs",
];

export type Material = (typeof materials)[number];

export type WebMapProgress = "CREATED" | "IN_PROGRESS" | "FAILED";

export interface Webmap {
    id: number;
    arcgisWebmapId: string;
    user: ProjectUser;
    progress: WebMapProgress;
}
