import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {Box, IconButton, Stack} from "@mui/material";
import React from "react";
import ModalImage from "react-modal-image";

export default function ImageCarousel({
    imageList,
    selectedImageIndex,
    setSelectedImageIndex,
}: {
    imageList: Array<[string, string]>;
    selectedImageIndex: number;
    setSelectedImageIndex: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement {
    const makeFpvImageFirst = (imageList: Array<[string, string]>): Array<[string, string]> => {
        // TODO
        const fpvImageIndex = imageList.findIndex(image => image[0] === "fpv_media");
        if (fpvImageIndex <= 0) return imageList;
        return [imageList[fpvImageIndex], ...imageList.filter(image => image[0] !== "fpv_media")];
    };

    const sortedImageList = makeFpvImageFirst(imageList);
    return (
        <Box width="100%" height="auto" position="relative">
            <Box position="relative" width="100%" height="auto">
                <IconButton
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: "45%",
                        padding: 0,
                    }}
                    onClick={() => {
                        if (selectedImageIndex > 0) {
                            setSelectedImageIndex(selectedImageIndex - 1);
                        } else {
                            setSelectedImageIndex(sortedImageList.length - 1);
                        }
                    }}>
                    <ChevronLeft color="secondary" fontSize="large" />
                </IconButton>
                <IconButton
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: "45%",
                        padding: 0,
                    }}
                    onClick={() => {
                        if (selectedImageIndex < sortedImageList.length - 1) {
                            setSelectedImageIndex(selectedImageIndex + 1);
                        } else {
                            setSelectedImageIndex(0);
                        }
                    }}>
                    <ChevronRight color="secondary" fontSize="large" />
                </IconButton>
                {sortedImageList[selectedImageIndex] && (
                    <ModalImage
                        small={sortedImageList[selectedImageIndex][1]}
                        large={sortedImageList[selectedImageIndex][1]}
                        showRotate={true}
                    />
                )}
            </Box>
            <Stack direction="row" justifyContent="center" spacing={1} mt={1}>
                {sortedImageList.map((image, index) => (
                    <Box
                        key={index}
                        sx={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: index === selectedImageIndex ? "primary.dark" : "secondary.dark",
                            margin: "0 4px",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelectedImageIndex(index)}
                    />
                ))}
            </Stack>
        </Box>
    );
}
