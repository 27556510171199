import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import React from "react";
import {type ArrayActions} from "hooks/useArray";
import {ExpandMore, Tune} from "@mui/icons-material";
import {type Layer} from "components/Map/createDataHelpers";
import {useProject} from "contexts/ProjectContext";

export const layerGroups: Record<"Scan Features" | "Panel Features" | "Manual Tags" | "Population Data", string[]> = {
    "Scan Features": [
        "Curb Ramp",
        "Midblock Accessibility",
        "Run Slope",
        "Cross Slope",
        "Clear Width",
        "Deterioration Layer",
    ],
    "Panel Features": ["Obstructions", "Cross Slope Panel"],
    "Manual Tags": [],
    "Population Data": ["Census", "Points of Interest"],
};

export function FilterMenu({
    visibleLayers,
    setVisibleLayers,
}: {
    visibleLayers: Layer[][];
    setVisibleLayers: Array<ArrayActions<Layer>>;
}): React.ReactElement {
    const {projectManualTags: manualTags} = useProject();
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const openFilterMenu = Boolean(anchor);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchor(anchor === null ? event.currentTarget : null);
    };

    const handleClose = (): void => {
        setAnchor(null);
    };

    const layerGroups: Record<"Scan Features" | "Panel Features" | "Manual Tags" | "Population Data", string[]> =
        React.useMemo(() => {
            return {
                "Scan Features": [
                    "Curb Ramp",
                    "Midblock Accessibility",
                    "Run Slope",
                    "Cross Slope",
                    "Clear Width",
                    "Deterioration Layer",
                ],
                "Panel Features": ["Obstructions", "Cross Slope Panel"],
                "Manual Tags": manualTags?.map(tag => tag.name) ?? [],
                "Population Data": ["Census", "Points of Interest"],
            };
        }, [manualTags]);

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                aria-haspopup="true"
                sx={theme => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[4],
                    cursor: "pointer",

                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                    },
                })}>
                <Tune fontSize="large" />
            </IconButton>
            <Menu open={openFilterMenu} anchorEl={anchor} onClose={handleClose}>
                {Object.entries(layerGroups).map(([group, layers], index) => (
                    <Accordion
                        key={group}
                        elevation={0}
                        sx={{
                            p: 0,
                            background: "transparent",
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography pl={1} fontSize="0.9rem" color="rgba(0, 0, 0, 0.54)">
                                {group}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{p: 0}}>
                            <MenuList dense>
                                {layers.map(layer => (
                                    <MenuItem
                                        key={layer}
                                        onClick={() => {
                                            const currentLayersArray = visibleLayers[index];
                                            const currentSetter = setVisibleLayers[index];

                                            if (currentLayersArray.includes(layer)) {
                                                currentSetter.remove(currentLayersArray.indexOf(layer));
                                            } else {
                                                if (group === "Scan Features") {
                                                    // if this is a scan feature group, only allow one layer at a time
                                                    // so remove all other layers in this group
                                                    // however, if the midblock accessibility layer is already selected, dont remove it if layer = curb ramp
                                                    // chatgpt did not help me with this
                                                    if (
                                                        (currentLayersArray.includes("Midblock Accessibility") &&
                                                            layer === "Curb Ramp") ||
                                                        (currentLayersArray.includes("Curb Ramp") &&
                                                            layer === "Midblock Accessibility")
                                                    ) {
                                                        currentSetter.overwrite([
                                                            "Curb Ramp",
                                                            "Midblock Accessibility",
                                                        ]);
                                                    } else {
                                                        currentSetter.overwrite([layer]);
                                                    }
                                                } else {
                                                    currentSetter.push(layer);
                                                }
                                            }
                                        }}
                                        sx={theme => ({
                                            "&.MuiMenuItem-root": {
                                                color: visibleLayers[index].includes(layer) ? "white" : "black",
                                                backgroundColor:
                                                    visibleLayers[index].includes(layer) && group === "Manual Tags"
                                                        ? manualTags?.filter(tag => tag.name === layer)[0].color
                                                        : visibleLayers[index].includes(layer)
                                                          ? theme.palette.deepWalkBlue.main
                                                          : "white",
                                                "&:hover": {
                                                    backgroundColor: theme.palette.action.hover,
                                                },
                                            },
                                        })}>
                                        {layer === "Deterioration Layer" ? "Deterioration" : layer}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Menu>
        </Box>
    );
}
