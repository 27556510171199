import {z} from "zod";

export enum UserRole {
    admin = "ADMIN",
    scanner = "scanner",
    annotator = "annotator",
    user = "user", // deprecated
    inactive = "inactive",
    disabled = "disabled",
}

export enum UserSubRole {
    external = "external",
    internal = "internal",
    label_review = "label_review",
}

export const loginResponseSchema = z.object({
    accessToken: z.string(),
    admin: z.boolean(),
    email: z.string(),
    id: z.number(),
    role: z.nativeEnum(UserRole),
    subRole: z.nativeEnum(UserSubRole),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
});

export type LoginResponseType = z.infer<typeof loginResponseSchema>;

export const webUserSchema = z.object({
    jwt: z.string(),
    id: z.number(),
    role: z.string(),
    admin: z.boolean(),
    email: z.string(),
    subRole: z.nativeEnum(UserSubRole),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    spoofed: z.boolean().optional(),
});

export type WebUser = z.infer<typeof webUserSchema>;

export const annotatorUserSchema = z.object({
    annotatorUser: z.object({
        id: z.number(),
        userId: z.number(),
        email: z.string(),
        passRate: z.number().optional(),
        approvedStages: z.array(z.string()),
        createdAt: z.string().pipe(z.coerce.date()).optional(),
        updatedAt: z.string().pipe(z.coerce.date()).optional(),
    }),
    annotatorBatchMetrics: z.object({
        scanBatchs: z.array(
            z.object({
                id: z.number(),
                passed: z.number(),
                failed: z.number(),
                completedAt: z.string().pipe(z.coerce.date()),
            }),
        ),
        stageToMetrics: z.record(
            z.object({
                hoursPerMile: z.number().nullable(),
            }),
        ),
    }),
});

export type AnnotatorUser = z.infer<typeof annotatorUserSchema>;

export const userSchema = z.object({
    id: z.number(),
    email: z.string(),
    role: z.nativeEnum(UserRole),
    isAdmin: z.boolean().optional(),
});

export type UserDB = z.infer<typeof userSchema>;
