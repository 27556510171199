import React from "react";
import {Box, Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {DashboardView} from "../Home";
import {Delete, FolderOpen, RotateLeft} from "@mui/icons-material";

export function SavedDashboards({
    currentDashboard,
    dashboards,
    currentDashboardView,
    setCurrentDashboardView,
    setDashboardToDelete,
}: {
    currentDashboard: string;
    dashboards: DashboardView[];
    currentDashboardView: DashboardView | undefined;
    setCurrentDashboardView: React.Dispatch<React.SetStateAction<DashboardView | undefined>>;
    setDashboardToDelete: React.Dispatch<React.SetStateAction<DashboardView | undefined>>;
}): React.ReactElement {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={currentDashboardView ? 0 : 2}
            boxSizing="border-box">
            <Stack direction="row" flexGrow={1} alignItems="center" gap={2}>
                <Typography>
                    <FolderOpen color="disabled" />
                </Typography>
                {dashboards.map(dashboard => (
                    <Chip
                        key={dashboard.id}
                        label={dashboard.name}
                        onClick={() =>
                            setCurrentDashboardView(currentDashboardView === undefined ? dashboard : undefined)
                        }
                        color={currentDashboardView?.id === dashboard.id ? "primary" : undefined}
                        onDelete={() => setDashboardToDelete(dashboard)}
                        deleteIcon={<Delete />}
                    />
                ))}
                <Tooltip title="Reset Dashboard">
                    <IconButton onClick={() => setCurrentDashboardView(undefined)}>
                        <RotateLeft />
                    </IconButton>
                </Tooltip>
            </Stack>
            {currentDashboardView && (
                <Box
                    position="relative"
                    flexBasis="33%"
                    textAlign="center"
                    boxSizing="border-box"
                    p={2}
                    sx={{
                        backgroundColor: theme => theme.palette.secondary.main,
                        borderRadius: `8px 8px 0px 0px`,
                    }}>
                    <Typography variant="h2" my={0}>
                        {currentDashboardView.name}
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
