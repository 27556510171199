import {CircularProgress, Paper, Stack, Typography} from "@mui/material";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import type {MapScan} from "react_ct/types";
import {WarningRounded} from "@mui/icons-material";
import {apiRequest} from "react_ct/requests";

export default function ScanPreview({scan}: {scan: MapScan}): React.ReactElement {
    const retrieveOneRotatedRaster = async (
        scan: MapScan,
    ): Promise<{scanId: number; folderName: string; rotatedImageUrl: string; imageUrl: string}> => {
        if (!scan.folderName) throw new Error("Scan does not have a folder name");
        const response = await apiRequest({
            path: "scan/read-urls",
            params: {scanId: scan.id, rotatedImageUrl: true, imageUrl: true},
        });

        return {
            scanId: scan.id,
            folderName: scan.folderName,
            rotatedImageUrl: response.data.rotatedImageUrl,
            imageUrl: response.data.imageUrl,
        };
    };

    const {data: imageUrl, isPending} = useQuery({
        queryKey: ["isImageValid", scan.id],
        queryFn: async () => {
            try {
                const imageUrls = await retrieveOneRotatedRaster(scan);
                const checkIfImagesAreValid = await Promise.all([
                    fetch(imageUrls.rotatedImageUrl),
                    fetch(imageUrls.imageUrl),
                ]);
                if (checkIfImagesAreValid[0].ok) return imageUrls.rotatedImageUrl;
                else if (checkIfImagesAreValid[1].ok) return imageUrls.imageUrl;
                else throw new Error("Scan does not have any valid rasters");
            } catch (error) {
                const e: Error = error as Error;
                console.error("an error occured", error);
                throw e;
            }
        },
        enabled: !!scan,
    });

    return (
        <Paper
            sx={{position: "absolute", right: 0, mt: 4, mr: 4, width: "33vw", maxHeight: "calc(100vh - 16px)", p: 2}}>
            <Stack direction="row" alignItems="baseline" flexWrap="wrap" justifyContent="space-between" mb={2}>
                <Typography>#{scan.id}</Typography>
                <Typography>{scan.folderName}</Typography>
            </Stack>
            {imageUrl && !isPending ? (
                <img src={imageUrl} width="100%" height="auto" style={{borderRadius: 4}} />
            ) : isPending ? (
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
                    <WarningRounded />
                    <Typography color="dimgray" textAlign="center">
                        Could not load scan image
                    </Typography>
                </Stack>
            )}
        </Paper>
    );
}
